require('formdata-polyfill');

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContactFormItems from '../../components/contact-form-items';
import Button from '../../components/button-component';
import Heading from '../../components/headline';
import ContentBlock from '../../components/content-block-module';
import { submitForm } from '../../actions/forms.js';
import { getSelectOptionsFromString } from '../../components/select/helpers/get-options-from-string';

import './style.styl';

class ContactForm extends React.Component {
    static parseProps(atts) {
        const newProps = {
            successTitle: atts.successTitle || '',
            successMessage: atts.successMessage || '',
            nameEnable: atts.nameEnable ? true : false,
            nameRequired: atts.nameRequired ? true : false,
            nameErrorMessage: atts.nameErrorMessage || 'enter a valid value',
            nameInfoText: atts.nameInfoText || '',
            companyEnable: atts.companyEnable ? true : false,
            companyRequired: atts.companyRequired ? true : false,
            companyErrorMessage: atts.companyErrorMessage || 'enter a valid value',
            companyInfoText: atts.companyInfoText || '',
            companyRegistrationNumberEnable: atts.companyRegistrationNumberEnable ? true : false,
            companyRegistrationNumberRequired: atts.companyRegistrationNumberRequired ? true : false,
            companyRegistrationNumberErrorMessage: atts.companyRegistrationNumberErrorMessage || 'enter a valid value',
            companyRegistrationNumberInfoText: atts.companyRegistrationNumberInfoText || '',
            companyIdNumberEnable: atts.companyIdNumberEnable ? true : false,
            companyIdNumberRequired: atts.companyIdNumberRequired ? true : false,
            companyIdNumberLabel: atts.companyIdNumberLabel || 'Company ID',
            companyIdNumberErrorMessage: atts.companyIdNumberErrorMessage || 'enter a valid value',
            companyIdNumberInfoText: atts.companyIdNumberInfoText || '',
            customerNumberEnable: atts.customerNumberEnable ? true : false,
            customerNumberRequired: atts.customerNumberRequired ? true : false,
            customerNumberLabel: atts.customerNumberLabel || 'Customer number',
            customerNumberErrorMessage: atts.customerNumberErrorMessage || 'enter a valid value',
            customerNumberInfoText: atts.customerNumberInfoText || '',
            emailEnable: atts.emailEnable ? true : false,
            emailRequired: atts.emailRequired ? true : false,
            emailErrorMessage: atts.emailErrorMessage || 'enter a valid value',
            emailInfoText: atts.emailInfoText || '',
            phoneEnable: atts.phoneEnable ? true : false,
            phoneRequired: atts.phoneRequired ? true : false,
            phoneErrorMessage: atts.phoneErrorMessage || 'enter a valid value',
            phoneInfoText: atts.phoneInfoText || '',
            employerEnable: atts.employerEnable ? true : false,
            employerRequired: atts.employerRequired ? true : false,
            employerErrorMessage: atts.employerErrorMessage || 'enter a valid value',
            employerInfoText: atts.employerInfoText || '',
            dropdownEnable: atts.dropdownEnable ? true : false,
            dropdownLabel: atts.dropdownLabel || '',
            dropdownValues: atts.dropdownValues || '',
            genericTextEnable: atts.genericTextEnable ? true : false,
            genericTextRequired: atts.genericTextRequired ? true : false,
            genericTextErrorMessage: atts.genericTextErrorMessage || 'enter a valid value',
            genericTextInfoText: atts.genericTextInfoText || '',
            genericTextLabel: atts.genericTextLabel || 'Enter text',
            genericTextDefault: atts.genericTextDefaultText || '',
            optInEnable: atts.optInEnable ? true : false,
            optInRequired: atts.optInRequired ? true : false,
            optInErrorMessage: atts.optInErrorMessage || 'enter a valid value',
            optInInfoText: atts.optInInfoText || '',
            newsletterEnable: atts.newsletterEnable ? true : false,
            newsletterRequired: atts.newsletterRequired ? true : false,
            newsletterErrorMessage: atts.newsletterErrorMessage || 'enter a valid value',
            newsletterInfoText: atts.newsletterInfoText || '',
            messageEnable: atts.messageEnable ? true : false,
            messageRequired: atts.messageRequired ? true : false,
            messageErrorMessage: atts.messageErrorMessage || 'enter a valid value',
            messageInfoText: atts.messageInfoText || '',
            filesEnable: atts.filesEnable ? true : false,
            filesRequired: atts.filesRequired ? true : false,
            filesErrorMessage: atts.filesErrorMessage || 'enter a valid value',
            filesSizeErrorMessage: atts.fileSizeErrorMessage || 'File is too big.',
            filesInfoText: atts.filesInfoText || '',
            filesDropText: atts.filesDropText,
            bankNameEnable: atts.bankNameEnable ? true : false,
            bankNameRequired: atts.bankNameRequired ? true : false,
            bankNameErrorMessage: atts.bankNameErrorMessage || 'enter a valid value',
            bankNameInfoText: atts.bankNameInfoText || '',
            clearingNumberEnable: atts.clearingNumberEnable ? true : false,
            clearingNumberRequired: atts.clearingNumberRequired ? true : false,
            clearingNumberErrorMessage: atts.clearingNumberErrorMessage || 'enter a valid value',
            clearingNumberInfoText: atts.clearingNumberInfoText || '',
            accountNumberEnable: atts.accountNumberEnable ? true : false,
            accountNumberRequired: atts.accountNumberRequired ? true : false,
            accountNumberErrorMessage: atts.accountNumberErrorMessage || 'enter a valid value',
            accountNumberInfoText: atts.accountNumberInfoText || '',
            socialSecurityNumberEnable: atts.socialSecurityNumberEnable ? true : false,
            socialSecurityNumberRequired: atts.socialSecurityNumberRequired ? true : false,
            socialSecurityNumberErrorMessage: atts.socialSecurityNumberErrorMessage || 'enter a valid value',
            socialSecurityNumberInfoText: atts.socialSecurityNumberInfoText || '',
            generalRequiredErrorMessage: atts.generalRequiredErrorMessage || 'required',
            generalSubmitErrorMessage: atts.generalSubmitErrorMessage || 'error on submit',
            generalSubmitButtonText: atts.generalSubmitButtonText || '',
            formId: atts.formId || '',
            permalink: atts.permalink,
            resetLink: atts.resetLink || {},
            gtmOptions: {
                paymentInsurance: atts.gtmPaymentInsurance,
            },
        };

        return newProps;
    }

    static vcConvertToProps(atts) {
        let newProps = {
            successTitle: atts.success_title || '',
            successMessage: atts.success_message || '',
            nameEnable: atts.name_enable ? true : false,
            nameRequired: atts.name_required ? true : false,
            nameErrorMessage: atts.name_error_message || 'enter a valid value',
            nameInfoText: atts.name_info_text || '',
            companyEnable: atts.company_enable ? true : false,
            companyRequired: atts.company_required ? true : false,
            companyErrorMessage:
                atts.company_error_message || 'enter a valid value',
            companyInfoText: atts.company_info_text || '',
            companyRegistrationNumberEnable: atts.company_registration_number_enable
                ? true
                : false,
            companyRegistrationNumberRequired: atts.company_registration_number_required
                ? true
                : false,
            companyRegistrationNumberErrorMessage:
                atts.company_registration_number_error_message ||
                'enter a valid value',
            companyRegistrationNumberInfoText:
                atts.company_registration_number_info_text || '',
            companyIdNumberEnable: atts.company_id_number_enable ? true : false,
            companyIdNumberRequired: atts.company_id_number_required
                ? true
                : false,
            companyIdNumberLabel: atts.company_id_number_label || 'Company ID',
            companyIdNumberErrorMessage:
                atts.company_id_number_error_message || 'enter a valid value',
            companyIdNumberInfoText: atts.company_id_number_info_text || '',
            customerNumberEnable: atts.customer_number_enable ? true : false,
            customerNumberRequired: atts.customer_number_required
                ? true
                : false,
            customerNumberLabel:
                atts.customer_number_label || 'Customer number',
            customerNumberErrorMessage:
                atts.customer_number_error_message || 'enter a valid value',
            customerNumberInfoText: atts.customer_number_info_text || '',
            emailEnable: atts.email_enable ? true : false,
            emailRequired: atts.email_required ? true : false,
            emailErrorMessage:
                atts.email_error_message || 'enter a valid value',
            emailInfoText: atts.email_info_text || '',
            phoneEnable: atts.phone_enable ? true : false,
            phoneRequired: atts.phone_required ? true : false,
            phoneErrorMessage:
                atts.phone_error_message || 'enter a valid value',
            phoneInfoText: atts.phone_info_text || '',
            employerEnable: atts.employer_enable ? true : false,
            employerRequired: atts.employer_required ? true : false,
            employerErrorMessage:
                atts.employer_error_message || 'enter a valid value',
            employerInfoText: atts.employer_info_text || '',
            dropdownEnable: atts.dropdown_enable ? true : false,
            dropdownLabel: atts.dropdown_label || '',
            dropdownValues: atts.dropdown_values || '',
            genericTextEnable: atts.generic_text_enable ? true : false,
            genericTextRequired: atts.generic_text_required ? true : false,
            genericTextErrorMessage:
                atts.generic_text_error_message || 'enter a valid value',
            genericTextInfoText: atts.generic_text_info_text || '',
            genericTextLabel: atts.generic_text_label || 'Enter text',
            genericTextDefault: atts.generic_text_default_text || '',
            optInEnable: atts.opt_in_enable ? true : false,
            optInRequired: atts.opt_in_required ? true : false,
            optInErrorMessage:
                atts.opt_in_error_message || 'enter a valid value',
            optInInfoText: atts.opt_in_info_text || '',
            newsletterEnable: atts.newsletter_enable ? true : false,
            newsletterRequired: atts.newsletter_required ? true : false,
            newsletterErrorMessage:
                atts.newsletter_error_message || 'enter a valid value',
            newsletterInfoText: atts.newsletter_info_text || '',
            messageEnable: atts.message_enable ? true : false,
            messageRequired: atts.message_required ? true : false,
            messageErrorMessage:
                atts.message_error_message || 'enter a valid value',
            messageInfoText: atts.message_info_text || '',
            filesEnable: atts.files_enable ? true : false,
            filesRequired: atts.files_required ? true : false,
            filesErrorMessage:
                atts.files_error_message || 'enter a valid value',
            filesSizeErrorMessage:
                atts.file_size_error_message || 'File is too big.',
            filesInfoText: atts.files_info_text || '',
            filesDropText: atts.files_drop_text,
            bankNameEnable: atts.bank_name_enable ? true : false,
            bankNameRequired: atts.bank_name_required ? true : false,
            bankNameErrorMessage:
                atts.bank_name_error_message || 'enter a valid value',
            bankNameInfoText: atts.bank_name_info_text || '',
            clearingNumberEnable: atts.clearing_number_enable ? true : false,
            clearingNumberRequired: atts.clearing_number_required
                ? true
                : false,
            clearingNumberErrorMessage:
                atts.clearing_number_error_message || 'enter a valid value',
            clearingNumberInfoText: atts.clearing_number_info_text || '',
            accountNumberEnable: atts.account_number_enable ? true : false,
            accountNumberRequired: atts.account_number_required ? true : false,
            accountNumberErrorMessage:
                atts.account_number_error_message || 'enter a valid value',
            accountNumberInfoText: atts.account_number_info_text || '',
            socialSecurityNumberEnable: atts.social_security_number_enable
                ? true
                : false,
            socialSecurityNumberRequired: atts.social_security_number_required
                ? true
                : false,
            socialSecurityNumberErrorMessage:
                atts.social_security_number_error_message ||
                'enter a valid value',
            socialSecurityNumberInfoText:
                atts.social_security_number_info_text || '',
            generalRequiredErrorMessage:
                atts.general_required_error_message || 'required',
            generalSubmitErrorMessage:
                atts.general_submit_error_message || 'error on submit',
            generalSubmitButtonText: atts.general_submit_button_text || '',
            formId: atts.form_id || '',
            permalink: atts.permalink,
            resetLink: atts.reset_link || {},
            gtmOptions: {
                paymentInsurance: atts.gtm_payment_insurance === 'true',
            },
        };
        return newProps;
    }


    static contextTypes = {
        localize: PropTypes.func,
        store: PropTypes.object,
        registerFetchAction: PropTypes.func,
    };

    static propTypes = {
        dropdownEnable: PropTypes.bool,
        dropdownLabel: PropTypes.string,
        dropdownValues: PropTypes.string,
        generalRequiredErrorMessage: PropTypes.string,
        generalSubmitErrorMessage: PropTypes.string,
        genericTextEnable: PropTypes.bool,
        genericTextDefault: PropTypes.string,
        genericTextLabel: PropTypes.string,
        dispatch: PropTypes.func,
        submitError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        submitLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        generalSubmitButtonText: PropTypes.string,
        permalink: PropTypes.string,
        successTitle: PropTypes.string,
        successMessage: PropTypes.string,
        formId: PropTypes.string,
        language: PropTypes.string,
        customer: PropTypes.object,
        locale: PropTypes.string,
        accounts: PropTypes.object,
        resetLink: PropTypes.object,
        gtmOptions: PropTypes.objectOf(PropTypes.bool),
    };

    constructor(props) {
        super(props);

        const initialFormValues = this.getInitialFormValues();

        this.state = {
            formValues: initialFormValues,
            success: false,
            customer: {
                name: null,
                address: null,
                firstEmail: null,
                secondEmail: null,
                homePhone: null,
                mobilePhone: null,
                selectedAccount: null,
            },
        };
    }

    getInitialFormValues() {
        const {
            genericTextEnable,
            genericTextLabel,
            genericTextDefault,
            dropdownEnable,
        } = this.props;
        return {
            ...(dropdownEnable && {
                dropdown: this.getInitialDropDownValue(),
            }),
            ...(genericTextEnable && {
                [genericTextLabel]: genericTextDefault,
            }),
        };
    }

    getInitialDropDownValue() {
        if (!this.props.dropdownEnable) {
            return null;
        }

        const options = this.props.dropdownValues
            ? getSelectOptionsFromString(this.props.dropdownValues)
            : '';

        if (Array.isArray(options) && options.length > 0) {
            return options[0].value || null;
        }

        return null;
    }

    // Prepare the props into separate objects for each enabled field
    getFormOptions() {
        const propKeys = Object.keys(this.props);
        const enabledFields = propKeys
            .filter(prop => prop.includes('Enable'))
            .filter(key => this.props[key])
            .map(key => key.split('Enable')[0]);

        // TODO: Make the ordering editable from WP. But for
        // now move social security number-field to top.
        const ssnIndex = enabledFields.indexOf('socialSecurityNumber');
        if (ssnIndex >= 0) {
            enabledFields.unshift(enabledFields.splice(ssnIndex, 1)[0]);
        }

        const optionsForEnabledFields = enabledFields.map(field => {
            const keys = Object.keys(this.props).filter(key =>
                key.includes(field)
            );

            const fieldObject = { fieldName: field };
            keys.forEach(key => {
                let newKey = key.split(field)[1];
                newKey = newKey.charAt(0).toLowerCase() + newKey.substr(1);
                fieldObject[newKey] = this.props[key];
            });

            return fieldObject;
        });

        return optionsForEnabledFields;
    }

    updateFormValue(fieldName, value) {
        this.setState({
            formValues: { ...this.state.formValues, ...{ [fieldName]: value } },
        });
    }

    toggleCheckboxValue(fieldName) {
        const currentValue = this.state.formValues[fieldName];

        if (typeof currentValue === 'undefined') {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    ...{ [fieldName]: true },
                },
            });
        } else {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    ...{ [fieldName]: !currentValue },
                },
            });
        }
    }

    async handleSubmitButtonClick() {
        const {
            dispatch,
            permalink,
            formId,
            submitLoading,
            dropdownEnable,
            dropdownLabel,
            genericTextEnable,
            genericTextLabel,
            genericTextDefault,
        } = this.props;

        if (!submitLoading[formId]) {
            const formData = new FormData();

            Object.entries(this.state.formValues).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(value => {
                        formData.append(key, value);
                    });
                } else {
                    formData.set(this.props[`${key}Label`] || key, value);
                }
            });

            formData.set('permalink', permalink);
            formData.set('formId', formId);
            if (dropdownEnable && !formData.has(dropdownLabel)) {
                formData.set(dropdownLabel, this.getInitialDropDownValue());
            }

            if (
                genericTextEnable &&
                !formData.has(genericTextLabel) &&
                genericTextDefault
            ) {
                formData.set(genericTextLabel, genericTextDefault);
            }

            try {
                await submitForm(formData, {
                    gtmOptions: this.props.gtmOptions,
                })(dispatch, this.context.store.getState());

                this.setState({ success: true });

                this.resetFields();
            } catch (err) {
                // eslint-disable-line
            }
        }
    }

    resetFields() {
        this.setState({
            formValues: {},
        });
    }

    renderSuccess() {
        const { resetLink } = this.props;
        return (
            <Fragment>
                <Heading tag={1}>{this.props.successTitle}</Heading>
                <ContentBlock>
                    {this.props.successMessage}
                </ContentBlock>
                {resetLink && resetLink.url && resetLink.title &&
                    <Button
                        size="medium"
                        key="done"
                        linkTo={resetLink.url}
                        onClick={() => this.setState({ success: false })}
                    >
                        {resetLink.title}
                    </Button>
                }
                <hr />
            </Fragment>
        );
    }

    render() {
        const {
            submitLoading,
            submitError,
            formId,
            generalRequiredErrorMessage,
            generalSubmitErrorMessage,
            language,
            generalSubmitButtonText,
        } = this.props;

        const formOptions = this.getFormOptions();
        const loading = submitLoading[formId] ? submitLoading[formId] : false;
        const error = submitError[formId] ? submitError[formId] : false;
        const disableSubmit = false;

        if (this.state.success) {
            return this.renderSuccess();
        }

        return (
            <div className="contact-form">
                <ContactFormItems
                    formOptions={formOptions}
                    formValues={this.state.formValues}
                    generalRequiredErrorMessage={generalRequiredErrorMessage}
                    generalSubmitErrorMessage={generalSubmitErrorMessage}
                    handleInputChange={this.updateFormValue.bind(this)}
                    toggleCheckboxValue={this.toggleCheckboxValue.bind(this)}
                    handleSubmitButtonClick={this.handleSubmitButtonClick.bind(
                        this
                    )}
                    submitError={error}
                    submitLoading={loading}
                    disableSubmit={disableSubmit}
                    submitText={generalSubmitButtonText}
                    language={language}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        submitError: state.forms.submitError,
        submitLoading: state.forms.submitLoading,
        language: state.localization.language,
        locale: state.localization.locale,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

// shallow component ?
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
