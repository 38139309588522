import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Link from '../link';

import './style.styl';

class Checkbox extends React.Component {
    static propTypes = {
        checked: PropTypes.bool,
        defaultChecked: PropTypes.bool,
        disabled: PropTypes.bool,
        errorMessage: PropTypes.string,
        handleInputBlur: PropTypes.func,
        handleInputClick: PropTypes.func,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        linkText: PropTypes.string,
        linkUrl: PropTypes.string,
        handleLinkClick: PropTypes.func,
        name: PropTypes.string,
        onCheck: PropTypes.func,
        reportValidStatus: PropTypes.func,
        required: PropTypes.bool,
        rounded: PropTypes.bool, // This applies style for the checkbox to be rounded
        style: PropTypes.object, // An object with styles that should be overwritten: {width: '500px', height: '100px'}
        styletype: PropTypes.oneOf([
            'filled',
            'outline',
            'filled-green',
            'filled-green-disabled',
            'filled-grey',
            'filled-grey-disabled',
        ]),
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        useMargin: PropTypes.bool,
        inheritFontSize: PropTypes.bool,
        wrapperClassNames: PropTypes.string,
    };

    static defaultProps = {
        name: '',
        label: '',
        disabled: false,
        defaultChecked: false,
        style: {},
        styletype: 'filled',
        type: 'checkbox',
        value: 'on',
        useMargin: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: undefined,
            open: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { reportValidStatus, name, checked, required } = this.props;

        if (required) {
            if (checked !== prevProps.checked && reportValidStatus) {
                reportValidStatus(name, checked);
            }
        }
    }

    visit(visited) {
        this.setState({ visited: visited !== false });
    }

    handleCheck = event => {
        this.visit();

        if (typeof this.props.onCheck === 'function') {
            this.props.onCheck(event);
        }
    };

    getErrorMessage() {
        if (this.props.required && this.state.visited && !this.props.checked) {
            return (
                <p className="checkbox-container--error-message">
                    {this.props.errorMessage}
                </p>
            );
        }

        return null;
    }

    render() {
        const props = this.props;

        const styletype = props.styletype;

        const divClasses = ClassNames('checkbox', props.wrapperClassNames);

        const spanClasses = ClassNames({
            [`checkbox-checkmark-${styletype}`]: true,
            'checkbox-checker-rounded': this.props.rounded,
            'checkbox-checkmark-radio': this.props.type === 'radio'
        });

        const labelClasses = ClassNames({
            [`checkbox-${styletype}`]: true,
            'checkbox-label-no-margin': !props.useMargin,
            'checkbox-label-inherit-font-size': props.inheritFontSize,
        });

        return (
            <div className={divClasses}>
                <div className="checkbox-container">
                    <label className={labelClasses}>
                        <input
                            className="checkbox-checker"
                            type={this.props.type}
                            onChange={this.handleCheck}
                            name={props.name}
                            disabled={props.disabled}
                            style={props.style}
                            onClick={this.props.handleInputClick}
                            onBlur={this.props.handleInputBlur}
                            checked={this.props.checked || false}
                            value={this.props.value}
                        />
                        <span onClick={this.props.handleInputClick} className={spanClasses}></span>

                        {props.linkUrl && props.linkText ? (
                            <span>
                                {props.label}{' '}
                                <Link onClick={props.handleLinkClick || null} to={props.linkUrl} target="_blank">
                                    {props.linkText}
                                </Link>
                            </span>
                        ) : (
                            <span>{props.label}</span>
                        )}
                    </label>
                </div>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default Checkbox;
