import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icons from '../icons';
import Loading from '../loading';
import styleVariables from '../../variables/style';

import './style.styl';

const Select = ({
    name,
    ariaLabel,
    disabled,
    label,
    boldLabel,
    arrow,
    value,
    placeholder,
    infoText,
    options,
    classNames,
    styletype,
    withInitialPlaceholder,
    errorMessage,
    isVisited,
    isValid,
    onChange,
    onKeyUp,
    onBlur,
    loading,
}) => {
    const [showInfoText, setShowInfoText] = useState(false);

    const renderPlaceholder = withInitialPlaceholder && (
        <option value="">{placeholder}</option>
    );

    const renderInfoIcon = infoText && (
        <div
            role="button"
            tabIndex="0"
            className="select--info"
            onKeyUp={() => setShowInfoText(!showInfoText)}
            onClick={() => setShowInfoText(!showInfoText)}
        >
            <Icons
                name="helpCircled"
                color={styleVariables.colors.colorRawbBlack}
                className="select--info--icon"
            />
        </div>
    );

    const renderLabel = label && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label htmlFor={name} className={cx('select--label', classNames.label)}>
            {boldLabel ? <strong>{label}</strong> : label}
        </label>
    );

    const renderOptions = options.map((item, index) => (
        <option key={index} value={item.value}>
            {item.text}
        </option>
    ));

    const renderInfo = showInfoText && (
        <div className="select--info-text">{infoText}</div>
    );

    const renderErrorMessage = isVisited && !isValid && (
        <p className="select--error-message">{errorMessage}</p>
    );

    const statusIcon = isValid
        ? {
            name: 'checkMark',
            color: styleVariables.colors.formValid,
        }
        : {
            name: 'close',
            color: styleVariables.colors.formInvalid,
        };

    const renderStatusIcon = isVisited && (
        <div className="select--status-icon" htmlFor={name}>
            <Icons
                name={statusIcon.name}
                color={statusIcon.color}
                className="select--status-icon--icon"
            />
        </div>
    );

    const renderArrow = arrow && (
        <Icons
            className="select--arrow"
            name="arrow-down-edge"
            width="20px"
            height="25px"
        />
    );

    const renderLoader = loading && <Loading miniLoader />;

    const wrapperClasses = cx(
        'select',
        { 'select--valid': isVisited && value && isValid },
        { 'select--invalid': isVisited && !isValid },
        classNames.wrapper
    );

    const containerClasses = cx('select--container', classNames.container);

    const selectWrapper = cx(
        'select--select-wrapper',
        classNames.selectWrapper
    );

    const selectClasses = cx(`select--${styletype}`, classNames.select);

    const selectButtonClasses = cx('select--buttons', classNames.buttons);

    return (
        <div className={wrapperClasses}>
            {renderLabel}
            <div className={containerClasses}>
                <div className={selectWrapper}>
                    <select
                        id={name}
                        className={selectClasses}
                        disabled={disabled}
                        name={name}
                        value={value}
                        aria-label={ariaLabel}
                        onChange={onChange}
                        onBlur={onBlur}
                        onKeyUp={onKeyUp}
                    >
                        {renderPlaceholder}
                        {renderOptions}
                    </select>
                    <div className={selectButtonClasses}>
                        {renderLoader}
                        {renderStatusIcon}
                        {renderArrow}
                    </div>
                </div>
                {renderInfoIcon}
            </div>
            {renderErrorMessage}
            {renderInfo}
        </div>
    );
};

Select.defaultProps = {
    classNames: {},
    value: '',
    name: '',
    placeholder: 'Select',
    styletype: 'default',
    arrow: true,
};

Select.propTypes = {
    boldLabel: PropTypes.bool,
    arrow: PropTypes.bool,
    disabled: PropTypes.bool,
    isVisited: PropTypes.bool,
    isValid: PropTypes.bool,
    withInitialPlaceholder: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    infoText: PropTypes.string,
    errorMessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    classNames: PropTypes.shape({
        wrapper: PropTypes.string,
        container: PropTypes.string,
        selectWrapper: PropTypes.string,
        select: PropTypes.string,
        buttons: PropTypes.string,
        label: PropTypes.string,
    }),
    styletype: PropTypes.oneOf(['default']),
    onChange: PropTypes.func,
    onKeyUp: PropTypes.func,
    onBlur: PropTypes.func,
    loading: PropTypes.bool,
};

export default Select;
