import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Input from '../input';
import Checkbox from '../checkbox';
import Button from '../button-component';
import FileUpload from '../file-upload';
import Select from '../select';
import { getSelectOptionsFromString } from '../select/helpers/get-options-from-string';

import './style.styl';

class ContactFormItems extends React.Component {

    static propTypes = {
        formOptions: PropTypes.array,
        formValues: PropTypes.object,
        generalRequiredErrorMessage: PropTypes.string,
        generalSubmitErrorMessage: PropTypes.string,
        handleInputChange: PropTypes.func,
        toggleCheckboxValue: PropTypes.func,
        handleSubmitButtonClick: PropTypes.func,
        submitError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        submitLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        submitText: PropTypes.string,
        openErrorMessages: PropTypes.object,
        toggleOpenStateOfErrorMessage: PropTypes.func,
        language: PropTypes.string,
        disableSubmit: PropTypes.bool,
    }

    state = {
        validStatusOfFields: {},
        formValid: false,
        formValues: {}
    }

    fields = new Set()

    resetStatus () {
        this.setInitialValidStatusOfFields();

        this.fields.forEach(field => {
            field && field.visit && field.visit(false);
        });
    }

    componentDidMount() {
        this.setInitialValidStatusOfFields();
    }

    registerField = (field) => {
        this.fields.add(field);
    }

    handleChange(fieldName, event) {
        this.props.handleInputChange(fieldName, event.target.value);
    }

    handleFilesChange(fieldName, files) {
        this.props.handleInputChange(fieldName, files);
    }

    handleCheck(fieldName) {
        this.props.toggleCheckboxValue(fieldName);
    }

    handleSubmitButtonClick() {
        this.props.handleSubmitButtonClick();
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.formValues !== this.props.formValues && Object.keys(nextProps.formValues).length === 0) {
            this.resetStatus();
        }
    }

    reportValidStatus(fieldId, validStatus) {

        const newStatusObjects = this.state.validStatusOfFields;
        newStatusObjects[fieldId] = validStatus;

        this.setState({
            validStatusOfFields: { ...this.state.validStatusOfFields, ...newStatusObjects}
        });

    }

    setInitialValidStatusOfFields() {

        const requiredFields = this.props.formOptions.filter( field => field.required === true);

        const statusObjects = requiredFields.reduce( (list, field) => {
            list[field.fieldName] = false;
            return list;
        }, {});

        this.setState({
            validStatusOfFields: statusObjects
        });

    }

    formatName(name) {
        let translatedName = this.context.localize(name);
        if ( name === 'newsletter' ) {
            translatedName = this.context.localize('newsletter-sign-up-phrase');
        } else if ( name === 'optIn') {
            translatedName = this.context.localize('opt-in');
        }
        return `${translatedName.charAt(0).toUpperCase()}${translatedName.substr(1)}`;
    }

    getValidationRule(name) {
        switch(name) {
            case 'email':
                return 'email';
            case 'phone':
                return 'phone';
            case 'socialSecurityNumber':
                return 'socialSecurityNumber';
            case 'companyRegistrationNumber':
                return 'companyRegistrationNumber';
            case 'companyIdNumber':
                return value => !isNaN(value) && value > 0;
            case 'accountNumber':
                return 'bankAccountAccountNumber';
            case 'clearingNumber':
                return 'bankAccountClearingNumber';
            default:
                return null;
        }
    }

    getCheckboxes(checkboxFieldNames) {
        const checkboxes = this.props.formOptions.filter( field => checkboxFieldNames.includes(field.fieldName));
        const checkboxClasses = ClassNames(
            'contact-form--group',
            'contact-form--group--checkbox-group'
        );

        return checkboxes ? (
            checkboxes.map( checkbox => (
                <div className="contact-form--group" key={`${checkbox.fieldName}+checkbox`}>
                    <Checkbox
                        name={checkbox.fieldName}
                        label={this.formatName(checkbox.fieldName)}
                        wrapperClassNames={checkboxClasses}
                        required={checkbox.required}
                        errorMessage={checkbox.errorMessage}
                        onCheck={this.handleCheck.bind(this, checkbox.fieldName)}
                        required={checkbox.required}
                        checked={this.props.formValues[checkbox.fieldName]}
                        reportValidStatus={this.reportValidStatus.bind(this)}
                        ref={this.registerField}
                    />
                </div>
            ))
        ) : null;
    }

    getSubmitButton() {
        const { submitLoading, disableSubmit, submitText } = this.props;
        let disabled = disableSubmit === true ? true : submitLoading;
        if (!disableSubmit) {
            for(let field in this.state.validStatusOfFields) {
                if (this.state.validStatusOfFields[field] === false) {
                    disabled = true;
                }
            }
        }

        const buttonText = submitLoading
            ? 'sending...'
            : (submitText || this.context.localize('submit'));
        return (
            <Button
                size="medium"
                disabled={disabled}
                onClick={this.handleSubmitButtonClick.bind(this)}
            >
                {buttonText}
            </Button>
        );
    }

    getSubmitError() {
        const { submitError }  = this.props;

        if (!submitError) {
            return null;
        }

        const message = submitError.status === 413
            ? this.context.localize('files_error')
            : this.props.generalSubmitErrorMessage;

        return (
            <p className="contact-form--submit-error--message">
                {message}
            </p>
        );
    }

    render() {

        const {
            formOptions,
            formValues,
            generalRequiredErrorMessage,
            generalSubmitErrorMessage,
            language
        } = this.props;

        const checkboxFieldNames = ['optIn', 'newsletter'];

        return (
            <div className="contact-form">
                { formOptions.map( (field, index) => {
                    // We want all checkboxes to be rendered last. So we skip them now
                    if (checkboxFieldNames.includes(field.fieldName)) {
                        return null;
                    }
                    if (field.fieldName === 'files') {
                        return (
                            <div className="contact-form--group" key={index}>
                                <FileUpload
                                    id={field.fieldName}
                                    name={field.fieldName}
                                    files={formValues[field.fieldName]}
                                    required={field.required}
                                    errorMessage={generalRequiredErrorMessage}
                                    fileSizeErrorMessage={field.sizeErrorMessage}
                                    label={this.formatName(field.fieldName)}
                                    onChange={this.handleFilesChange.bind(this, field.fieldName)}
                                    reportValidStatus={this.reportValidStatus.bind(this)}
                                    droptext={field.dropText}
                                    ref={this.registerField}
                                />
                            </div>
                        );
                    }

                    if (field.fieldName === 'dropdown') {
                        return (
                            <div className="contact-form--group" key={index}>
                                <Select
                                    id={field.fieldName}
                                    name={field.fieldName}
                                    label={field.label}
                                    options={getSelectOptionsFromString(field.values)}
                                    onChange={this.handleChange.bind(this, field.fieldName)}
                                    value={formValues[field.fieldName]}
                                    ariaLabel={'Select'}
                                />
                            </div>
                        );
                    }

                    const rows = field.fieldName === 'message' ? 4 : 1;

                    return (
                        <div className="contact-form--group" key={index}>
                            <Input
                                id={field.fieldName}
                                name={field.fieldName}
                                placeholder={field.label ? field.label : this.formatName(field.fieldName)}
                                onChange={this.handleChange.bind(this, field.fieldName)}
                                value={formValues[field.fieldName]}
                                infoText={field.infoText}
                                required={field.required}
                                validate={this.getValidationRule(field.fieldName)}
                                generalRequiredErrorMessage={generalRequiredErrorMessage}
                                generalSubmitErrorMessage={generalSubmitErrorMessage}
                                fieldErrorMessage={field.errorMessage}
                                reportValidStatus={this.reportValidStatus.bind(this)}
                                type="text"
                                rows={rows}
                                language={language}
                                ref={this.registerField}
                            />
                        </div>
                    );
                })}
                { this.getCheckboxes(checkboxFieldNames) }
                { this.getSubmitButton() }
                { this.getSubmitError() }
            </div>
        );
    }
}

ContactFormItems.contextTypes = {
    localize: PropTypes.func,
};

export default ContactFormItems;
