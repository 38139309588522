import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Label from '../label';

import DropZone from 'react-dropzone';

import './style.styl';

const MAX_FILE_SIZE = 15 * Math.pow(10, 6); // 15 million bytes ≈ 15Mb

export default class FileUpload extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        errorMessage: PropTypes.string,
        // the typeof check is mainly to prevent SSR from erroring
        files: PropTypes.arrayOf(PropTypes.instanceOf(typeof File !== 'undefined' ? File : Object)),
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string.isRequired,
        reportValidStatus: PropTypes.func,
        required: PropTypes.bool,
        droptext: PropTypes.string,
        fileSizeErrorMessage: PropTypes.string,
    }

    static defaultProps = {
        files: [],
    }

    constructor (props) {
        super(props);

        this.state = {
            visited: false,
            valid: !props.required || this.props.files.length > 0,
            fileTooBig: false
        };
    }

    visit(visited) {
        this.setState({ visited: visited !== false });
    }

    componentDidUpdate(prevProps) {
        const { files, id, reportValidStatus, required } = this.props;

        if (files !== prevProps.files) {
            const valid = !required || files.length > 0;

            if (valid !== this.state.valid) {
                this.setState({ valid });

                if (reportValidStatus) {
                    reportValidStatus(id, valid);
                }
            }
        }
    }

    onDrop = (acceptedFiles) => {
        this.visit();

        const newFiles = acceptedFiles.filter(acceptedFile => {
            return !this.props.files.find(file => (
                file.lastModified === acceptedFile.lastModified
                    && file.name === acceptedFile.name
            ));
        });

        if (newFiles.length >= 0) {
            const files = this.props.files.concat(newFiles);

            this.props.onChange(files);
        }
    }

    removeFile (file) {
        this.visit();

        const index = this.props.files.indexOf(file);

        if (index >= 0) {
            this.props.onChange([
                ...this.props.files.slice(0, index),
                ...this.props.files.slice(index +  1),
            ]);
        }
    }

    renderFileSizeError() {
        return this.state.fileTooBig
            ? <p className="file-upload--error-message">{this.props.fileSizeErrorMessage}</p>
            : null;
    }

    renderErrorMessage() {
        return this.state.visited && this.props.required && !this.props.files.length
            ? <p className="file-upload--error-message">{ this.props.errorMessage }</p>
            : null;
    }

    render () {
        const {
            className,
            id,
            name,
            label,
            required,
            files,
            droptext,
        } = this.props;

        return (
            <div className={cx('file-upload', className)}>
                { label ? <Label htmlFor={id} id={`${name}-${id}`} className="input--label">{`${label}${required ? ' *' : ''}`}</Label> : null }

                <div className="file-upload--files">
                    {files.map(file => (
                        <div key={`${file.name}-${file.lastModified}`} className="file-upload--files--file">
                            <div>{file.name}</div>
                            <button className='file-upload--files--file--button' onClick={() => this.removeFile(file)}>Remove</button>
                        </div>
                    ))}
                </div>

                <DropZone
                    onDrop={this.onDrop}
                    maxSize={MAX_FILE_SIZE}
                    onDropRejected={() => {
                        this.setState({
                            fileTooBig: true
                        });
                    }}
                    onDropAccepted={() => {
                        this.setState({
                            fileTooBig: false
                        });
                    }}
                >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div {...getRootProps()} className={cx({
                            'file-upload--drop-zone': true,
                            'file-upload--drop-zone--dragging': isDragActive,
                        })}>
                            <input {...getInputProps()} />
                            <div className='file-upload--drop-zone--text'>{droptext || 'Click or drag here to add files'}</div>
                        </div>

                    )}
                </DropZone>

                { this.renderFileSizeError() }
                { this.renderErrorMessage() }

            </div>
        );
    }
}
